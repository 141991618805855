import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={'Gift a Certificate of Deposit - Crypto Chanukah'}
            meta={[
              { name: 'description', content: 'US Savings Bonds are a thing of the past. Decentralized finance brings about a whole new level of do-it-yourself banking.' },
              { name: 'keywords', content: 'gift, crypto, savings bonds, crypto certificates, hanukkah, bar mitzvah, new year, chineese new year, certificate, baptism, baptism favors ' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
